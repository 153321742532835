import { memo, useCallback, useEffect, useMemo } from 'react';

import { formatAutocomplete } from '@/shared/lib/helpers/format-autocomplete.helper';

import * as Styled from './select-variables.styles';
import { SelectVariablesProps } from './select-variables.types';

const SelectVariablesComponent = ({
	searchInOptionsLabel,
	label,
	placeholder,
	options,
	values,
	isAll,
	setData,
	setAll,
	disabled,
	width,
}: SelectVariablesProps) => {
	const keys = useMemo(() => options.map(({ value }) => value), [options]);

	const onVariablesChange = useCallback(
		(variables: string[], setVariables: (values: string[]) => void, setIsAllVariables: (val: boolean) => void) =>
			(value: string) => {
				if (variables.includes(value)) {
					setVariables([...variables.filter((val) => val !== value)]);
					setIsAllVariables(false);
				} else {
					setVariables([...variables, value]);
				}
			},
		[],
	);

	const onSetAll = (variable: boolean, setAllVariables: (val: boolean) => void) => () => {
		setAllVariables(!variable);
	};

	useEffect(() => {
		if (isAll) {
			setData(keys);
		} else if (!isAll && values.length - 1 === keys.length) {
			setData([]);
		}
	}, [isAll, keys, setData, values]);

	return (
		<Styled.SelectVariables
			options={options}
			values={values}
			onChange={onVariablesChange(values, setData, setAll)}
			onSetAll={onSetAll(isAll, setAll)}
			isAll={isAll}
			searchInOptions
			searchInOptionsLabel={formatAutocomplete(options, values) || searchInOptionsLabel}
			placeholder={placeholder}
			label={label}
			disabled={disabled}
			width={width}
		/>
	);
};

export default memo(SelectVariablesComponent);
