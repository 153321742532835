import { memo } from 'react';

import Check from '@/shared/assets/icons/ui-kit/check.svg';

import { Text } from '../text';
import * as Styled from './checkbox.styles';
import { CheckboxProps } from './checkbox.types';

const CheckboxComponent = ({
	type = 'checkbox',
	text,
	width = '20px',
	height = '20px',
	checked,
	disabled = false,
	alignItems = 'center',
	onChange,
	marginBottom = 0,
	...props
}: CheckboxProps) => {
	return (
		<Styled.Wrapper onClick={onChange} disabled={disabled} alignItems={alignItems} marginBottom={marginBottom}>
			<Styled.InputContainer>
				<Styled.HiddenInput
					type={type}
					checked={checked}
					disabled={disabled}
					width={width}
					height={height}
					aria-label="Checkbox"
					{...props}
				/>
				<Styled.Input width={width} height={height} checked={checked} />
				{checked && <Styled.CheckIcon Svg={Check} />}
			</Styled.InputContainer>
			{text && <Text label={text} />}
		</Styled.Wrapper>
	);
};

export default memo(CheckboxComponent);
