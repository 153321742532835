import '@/shared/configs/i18n.config';

import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { App } from '@/app';
import { ErrorBoundary } from '@/app/providers/error-boundary';
import { StoreProvider } from '@/app/providers/store';

const rootElement = document.getElementById('root');

export const router = createBrowserRouter([
	{
		path: '/*',
		element: <App />,
	},
]);

if (rootElement) {
	const root = createRoot(rootElement);

	root.render(
		<StoreProvider>
			<HelmetProvider>
				<ErrorBoundary>
					<RouterProvider router={router} />
				</ErrorBoundary>
			</HelmetProvider>
		</StoreProvider>,
	);
} else {
	throw new Error('Root container missing in index.html');
}
