import { Box } from '@/shared/ui/box';
import { Text } from '@/shared/ui/text';

import { SizeText, StepsProps } from '../types/agreements-api.interface';

export enum Steps {
	TermsAndConditions = 'Step 1',
	PrivacyPolicy = 'Step 2',
	DataSharing = 'Step 3',
}

export const STEPS = (file: StepsProps, size: SizeText) => [
	{
		id: 'step1',
		title: 'Step 1',
		caption: 'Welcome to the D.gree platform: H-System',
		lastAcceptedCaption: 'Welcome back to the D.gree platform: H-System',
		lastAcceptedText:
			'To continue using the D.gree platform and services, please review the Terms and Conditions and the Privacy Policy.',
		text: 'To continue using the D.gree platform and services, please read and accept the Terms and Conditions and the Privacy Policy.',
		file: file.termsAndConditionsFile,
		checkboxes: [
			{
				text: (
					<Box gap={4}>
						<Text size={size} label="I have read and accept" />
						<Text size={size} label="Terms & Conditions" weight={600} />
					</Box>
				),
			},
			{
				text: (
					<Box direction="column" gap={12}>
						<Text
							size={size}
							label="I have read and accept each and all of the following clauses of the terms and conditions:"
						/>
						<Text
							size={size}
							label="Art. 3 (User Activation and use of the on - board software); Art. 4 (Intellectual
							property); Art. 6 (Support); Art. 7
							(Security); Art. 8 (Suspension and
							interruption of service); Art. 9 (Warranties and waivers); Art. 10
							(Limitation of liability); Art. 11
							(Withdrawal); Art. 12 (Termination); Art.
							13 (Amendments to terms and
							conditions); Art. 14 (Miscellaneous); Art.
							15 (Applicable law and jurisdiction)"
						/>
					</Box>
				),
			},
		],
	},
	{
		id: 'step2',
		title: 'Step 2',
		caption: 'Your privacy is important to us',
		text: 'We carefully store your data. To learn more, please read the Privacy Policy.',
		file: file.privacyPolicyFile,
		checkboxes: [
			{
				text: (
					<Box gap={4}>
						<Text size={size} label="I have read and accept" />
						<Text size={size} label="Privacy Policy" weight={600} />
					</Box>
				),
			},
		],
	},
	{
		id: 'step3',
		title: 'Step 3',
		caption: 'Improve the quality of services',
		text: 'Accept to share your data with the shipyard and third parties. You can always change your preferences by going to your profile.',
		checkboxes: [
			{
				text: 'I accept',
			},
		],
	},
];
