export enum UserRole {
	Admin = 'ADMIN',
	MultiBoat = 'MULTI_BOAT',
	SingleBoat = 'SINGLE_BOAT',
}

export enum TenantRole {
	Plant = 'PLANT',
	Boat = 'BOAT',
}

export enum CommonUserRole {
	Admin = UserRole.Admin,
	SingleEntity = UserRole.SingleBoat,
	MultipleEntity = UserRole.MultiBoat,
}
