import { UserFilterVariables, UserFilterVariablesResponse } from '../types/users-management-api.interface';

export const parseUserFilterVariables = ({
	user_types,
	roles,
	sectors,
	active_sources,
}: UserFilterVariablesResponse): UserFilterVariables => {
	return {
		userTypes: user_types
			? user_types.map((value) => ({
					value,
					label: value,
			  }))
			: [],
		roles: roles
			? roles.map((value) => ({
					value,
					label: value,
			  }))
			: [],
		sectors: sectors
			? sectors.map((value) => ({
					value,
					label: value,
			  }))
			: [],
		activeSources: active_sources
			? active_sources.map((value) => ({
					value,
					label: value,
			  }))
			: [],
	};
};
