import { FC, memo, useRef } from 'react';

import { generateUid } from '@/shared/lib/helpers/generate-uid.helper';

import { useModalActions } from '../model/slice/modal.slice';
import { ModalOptions } from '../model/types/modal.interface';

export const createModalHook = <T extends ModalOptions>(component: (props: T) => FC<unknown>) =>
	function useModal(): [(props?: Omit<T, keyof ModalOptions> & { onClose?: () => void }) => void, () => void] {
		const { removeModal, addModal } = useModalActions();
		const idRef = useRef<string>();

		const hideModal = () => {
			if (idRef.current) {
				removeModal(idRef.current);
			}
		};

		const createModal = (props: Omit<T, keyof ModalOptions> & { onClose?: () => void } = {} as T) => {
			const id = generateUid();

			idRef.current = addModal({
				id,
				component: memo(
					component({
						...props,
						onClose: props?.onClose || hideModal,
					} as T),
				),
			}).payload.id;
		};

		return [createModal, hideModal];
	};
