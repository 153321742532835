import styled from 'styled-components';

import { Autocomplete } from '@/shared/ui/autocomplete';

export const SelectVariables = styled(Autocomplete)<{ label?: string; disabled?: boolean; width?: string }>`
	width: ${({ theme: { autocomplete }, width }) => width ?? autocomplete.width.l};
	margin-top: ${({ label }) => !label && '23px'};
	pointer-events: ${({ disabled }) => disabled && 'none'};

	@media ${({ theme: { media } }) => media.mobile} {
		width: ${({ theme: { autocomplete } }) => autocomplete.width.full};
	}
`;
